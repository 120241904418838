import React from 'react';

const IconLogo = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 800 800">
    <title>Logo</title>
    <g transform="translate(-220.000000, -100.000000) scale(3)">
      <g>
        <path
          stroke="#982338"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M 50 250 L 90 250 L 105 230 L 120 250 L 140 250 L 150 260 L 180 120 L 200 270 L 210 250 L 220 250 L 232 220 L 245 250 L 276 250 "
        />
        <path
          d="M 150 260 L 180 120 L 200 270 "
          stroke="#ff4263"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M 162 202 L 194 202 "
          stroke="#ff4263"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
