import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 500">
    <title>Loader Logo</title>
    <g transform="translate(25.000000, 0)">
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50 250 L 90 250 L 105 230 L 120 250 L 140 250 L 150 260 L 180 120 L 200 270 L 210 250 L 220 250 L 232 220 L 245 250 L 276 250 "
        // d="M 50, 5
        //           L 11, 27
        //           L 11, 72
        //           L 50, 95
        //           L 89, 73
        //           L 89, 28 z"
      />
      <g id="B">
        <path
          stroke="#982338"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M 50 250 L 90 250 L 105 230 L 120 250 L 140 250 L 150 260 L 180 120 L 200 270 L 210 250 L 220 250 L 232 220 L 245 250 L 276 250 "
        />
        <path
          d="M 150 260 L 180 120 L 200 270 "
          stroke="#ff4263"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M 162 202 L 194 202 "
          stroke="#ff4263"
          strokeWidth="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </g>
  </svg>
);

export default IconLoader;
